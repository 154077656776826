import { requestFindVehicleVisualModel, requestUpdateVehicles } from 'api/vehicleInfo';
import { useEffect, useState, useContext, useCallback } from 'react';
import { ToastContext } from 'components/ToastProvider';

const useAssign3dModel = (vehicle, isShowDropdown, setShowDropdown, onToggle, updateVehicles) => {
    const { showToast } = useContext(ToastContext);
    const [isFindLoading, setIsFindLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [modelSearchValue, setModelSearchValue] = useState('');
    const [yearSearchValue, setYearSearchValue] = useState(-1);
    const [selectedVisualModel, setSelectedVisualModel] = useState(null);
    const [visualModelSearchResults, setVisualModelSearchResults] = useState([]);
    const [visualModelOptions, setVisualModelOptions] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);

    useEffect(() => {
        setVisualModelOptions(
            visualModelSearchResults.map(vm => ({
                label: `${vm.visualModelName}`,
                value: vm.visualModelId,
            }))
        );
    }, [visualModelSearchResults]);

    const findVisualModelForVehicle = useCallback(
        async (modelName, yearId) => {
            try {
                setIsFindLoading(true);
                let years = [];
                if (yearId > 0) years = [parseInt(yearId)];
                let response = await requestFindVehicleVisualModel(modelName, years);
                setVisualModelSearchResults(response.results);
                setResultsCount(response.count);
                if (response.count > 0) {
                    //first check if the vehicle has a set visual model that is in the search result
                    let selected =
                        vehicle.visualModel &&
                        response.results.some(x => x.visualModelId === vehicle.visualModel.visualModelId)
                            ? response.results.find(x => x.visualModelId === vehicle.visualModel.visualModelId)
                            : response.results[0];
                    setSelectedVisualModel({
                        label: `${selected.visualModelName}`,
                        value: selected.visualModelId,
                    });
                }
            } catch (error) {
                showToast();
            } finally {
                setIsFindLoading(false);
            }
        },
        [showToast, vehicle.visualModel]
    );

    const onShowDropDown = useCallback(async () => {
        //default the text box to current vehicle values
        setModelSearchValue(vehicle.model.modelName);
        setYearSearchValue(vehicle.year.yearId);
        //try init lookup using defaults
        await findVisualModelForVehicle(vehicle.model.modelName, vehicle.year.yearId);
    }, [vehicle.model.modelName, vehicle.year.yearId, findVisualModelForVehicle]);

    const handleFindClick = async () => {
        if (isFindLoading) return;
        setSelectedVisualModel([]);
        await findVisualModelForVehicle(modelSearchValue, yearSearchValue);
    };

    const handleCancelClick = useCallback(() => {
        setSelectedVisualModel([]);
        setVisualModelSearchResults([]);
        setResultsCount(0);
        setVisualModelOptions({});
        if (isShowDropdown) {
            setShowDropdown(false);
            onToggle(vehicle.vehicleId);
        }
    }, [isShowDropdown, onToggle, setShowDropdown, vehicle.vehicleId]);

    const handleVisualModelChange = useCallback(
        e => {
            setSelectedVisualModel(e);
        },
        [setSelectedVisualModel]
    );

    const handleSaveVehicleVisualModel = async () => {
        await setVehicleVisualModel(selectedVisualModel.value);
    };

    //apply the given selected visual model id to this vehicle instance
    const setVehicleVisualModel = async visualModelId => {
        try {
            setIsSaveLoading(true);
            let result = await requestUpdateVehicles([vehicle.vehicleId], vehicle.oemId, {
                visualModelId,
            });
            updateVehicles(result);
            // TODO: fix whatever the code below does
            //update the result on the vehicle
            vehicle.visualModel = result.visualModel;
            setShowDropdown(false);
            onToggle(vehicle.vehicleId);
        } catch (error) {
            showToast();
        } finally {
            setIsSaveLoading(false);
        }
    };

    const resetVehicleVisualModel = async () => {
        try {
            setIsSaveLoading(true);
            let success = await requestUpdateVehicles([vehicle.vehicleId], vehicle.oemId, {
                visualModelId: null,
            });
            updateVehicles(success);
            // TODO: fix whatever the code below does
            if (success) vehicle.visualModel = null;
            if (isShowDropdown) {
                setShowDropdown(false);
                onToggle(vehicle.vehicleId);
            }
        } catch (error) {
            showToast();
        } finally {
            setIsSaveLoading(false);
        }
    };

    return {
        isFindLoading,
        isSaveLoading,
        modelSearchValue,
        setModelSearchValue,
        yearSearchValue,
        setYearSearchValue,
        selectedVisualModel,
        visualModelOptions,
        resultsCount,
        onShowDropDown,
        handleFindClick,
        handleCancelClick,
        handleVisualModelChange,
        handleSaveVehicleVisualModel,
        resetVehicleVisualModel,
    };
};

export default useAssign3dModel;
