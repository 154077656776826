import { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { requestBooksForOemApi, requestMappingStatistics, requestOemBooksTaggerStats } from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';

const calculatePercentageStats = (totalInState, totalAmount) => {
    return totalAmount === 0 ? 0 : (totalInState / totalAmount) * 100;
};

const getMappingStatsAsync = async bookId => {
    const mappingStatistics = await requestMappingStatistics(bookId);
    const counters = mappingStatistics.proceduresCount;
    return {
        //for backward compability, to remove this part we need to remove all usages of old stats first
        old_way_statistics: {
            totalInReview: `${counters.inReview} / ${counters.total}`,
            totalMapped: `${counters.completed} / ${counters.total}`,
            totalNeedsHelp: `${counters.needHelp} / ${counters.total}`,
            totalLatestMajorProceduresToPublish: counters.latestMajorToPublish,
            inReviewPercentage: calculatePercentageStats(counters.inReview, counters.total),
            mappedPercentage: calculatePercentageStats(counters.completed, counters.total),
            needsHelpPercentage: calculatePercentageStats(counters.needHelp, counters.total),
        },
        statistics: {
            groupsInReview: `${counters.groupsInReview} / ${counters.total}`,
            typesInReview: `${counters.typesInReview} / ${counters.total}`,
            groupsCompleted: `${counters.groupsCompleted} / ${counters.total}`,
            typesCompleted: `${counters.typesCompleted} / ${counters.total}`,
            needHelp: `${counters.needHelp} / ${counters.total}`,
            groupsInReviewPercentage: calculatePercentageStats(counters.groupsInReview, counters.total),
            typesInReviewPercentage: calculatePercentageStats(counters.typesInReview, counters.total),
            groupsCompletedPercentage: calculatePercentageStats(counters.groupsCompleted, counters.total),
            typesCompletedPercentage: calculatePercentageStats(counters.typesCompleted, counters.total),
            needHelpPercentage: calculatePercentageStats(counters.needHelp, counters.total),
            onlyHotSheetMapped: counters.onlyHotSheetMapped,
            removedPending: counters.removedPending,
        },
    };
};

const useBooks = displayTaggerDetails => {
    const [books, setBooks] = useState([]);
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [bookStats, setBookStats] = useState(new Map());
    const [bookSearchValue, setBookSearchValue] = useState('');
    const [isNotCompletedFilter, setIsNotCompletedFilter] = useState(true);
    const [isRefreshedPendingFilter, setRefreshedPendingFilter] = useState(false);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const [isBooksStatsLoading, setBookStatsLoading] = useState(false);
    const { showToast } = useContext(ToastContext);
    const [isOnlyHotSheetFilter, setIsOnlyHotSheetFilter] = useState(false);
    let { oemId } = useParams();
    const bookStatsRef = useRef(new Map());

    useEffect(() => {
        if (books) {
            let newBooks = books;
            if (bookSearchValue !== '')
                newBooks = newBooks.filter(b => b.bookName.toLowerCase().includes(bookSearchValue.toLowerCase()));
            setFilteredBooks(newBooks);
        }
    }, [books, bookSearchValue]);

    useEffect(() => {
        let isUnmounted = false;
        const getBooksForOem = async () => {
            try {
                incrementLoading();
                const books = await requestBooksForOemApi(parseInt(oemId));
                books.sort((book1, book2) => book1.bookName.localeCompare(book2.bookName));
                if (!isUnmounted) {
                    setBooks(books);
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getBooksForOem();
        return () => {
            isUnmounted = true;
        };
    }, [oemId, showToast, incrementLoading, decrementLoading]);

    useEffect(() => {
        // useEffect only for tagger
        if (!displayTaggerDetails) return;

        const getStats = async () => {
            try {
                setBookStatsLoading(true);
                const taggerStats = await requestOemBooksTaggerStats(oemId);

                for (const stats of taggerStats) {
                    bookStatsRef.current.set(stats.bookId, {
                        bookId: stats.bookId,
                        stats: stats,
                        statistics: null,
                    });
                }
            } catch (error) {
                showToast(error);
            } finally {
                setBookStatsLoading(false);
            }

            setBookStats(new Map(bookStatsRef.current));
            return;
        };

        getStats();
    }, [displayTaggerDetails, oemId, showToast, incrementLoading, decrementLoading]);

    useEffect(() => {
        // useEffect only for mapper-old
        if (displayTaggerDetails) return;

        let isUnmounted = false;
        const getBookStatsAndDetails = async () => {
            // fetch the filtered books first
            try {
                for (let i = 0; i < filteredBooks.length && !isUnmounted; ++i) {
                    const { bookId } = filteredBooks[i];
                    if (!bookStatsRef.current.has(bookId)) {
                        const statsDisplay = await getMappingStatsAsync(bookId); //finally/ideally this should be separate hook for statistics
                        if (isUnmounted) {
                            // no need to fetch more data or update the state of this component
                            break;
                        } else {
                            bookStatsRef.current.set(bookId, {
                                bookId: bookId,
                                stats: statsDisplay.old_way_statistics,
                                statistics: statsDisplay.statistics,
                            });
                            setBookStats(new Map(bookStatsRef.current));
                        }
                    }
                }

                // fetch the remaining books in the background
                for (let i = 0; i < books.length && !isUnmounted; ++i) {
                    const { bookId } = books[i];
                    if (!bookStatsRef.current.has(bookId)) {
                        const statsDisplay = await getMappingStatsAsync(bookId);

                        if (isUnmounted) {
                            // no need to fetch more data or update the state of this component
                            break;
                        } else {
                            bookStatsRef.current.set(bookId, {
                                bookId: bookId,
                                stats: statsDisplay.old_way_statistics,
                                statistics: statsDisplay.statistics,
                            });
                            setBookStats(new Map(bookStatsRef.current));
                        }
                    }
                }
            } catch (error) {
                showToast(error);
            }
        };
        getBookStatsAndDetails();

        return () => {
            isUnmounted = true;
        };
    }, [books, filteredBooks, oemId, displayTaggerDetails, showToast]);

    const statsLoadingPercentage = () => {
        return Math.trunc((bookStats.size / books.length) * 100);
    };

    return {
        filteredBooks,
        bookStats,
        bookSearchValue,
        setBookSearchValue,
        isNotCompletedFilter,
        setIsNotCompletedFilter,
        isRefreshedPendingFilter,
        setRefreshedPendingFilter,
        statsLoadingPercentage,
        isBooksStatsLoading,
        isOnlyHotSheetFilter,
        setIsOnlyHotSheetFilter,
    };
};

export default useBooks;
