import { ApplicationPaths } from './AuthenticationConstants';

export class AuthenticationConfig {
    AUTH_CONFIG;
    METADATA_OIDC;

    constructor(settings) {
        if (!settings || !settings.authority || !settings.applicationUrl) throw new Error('Invalid settings');

        this.AUTH_CONFIG = {
            authority: settings.authority,
            client_id: 'oemiq.client.repairdeck',
            redirect_uri: `${settings.applicationUrl}${ApplicationPaths.LoginCallback}`,
            response_type: 'id_token token',
            scope: 'openid profile offline_access email oemiq.customers.api.scope oemiq.admin.api.scope oemiq.vehicleinfo.api.scope oemiq.analytics.api.scope oemiq.security.api.scope oemiq.repairplan.api.scope oemiq.repairprocedures.api.scope oemiq.search.api.scope.full oemiq.nvd.api.scope',
            post_logout_redirect_uri: `${settings.applicationUrl}/index.html`,
            automaticSilentRenew: true,
            accessTokenExpiringNotificationTime: 600,
            clockSkew: 1200,
        };

        this.METADATA_OIDC = {
            issuer: settings.authority,
            jwks_uri: settings.authority + '/.well-known/openid-configuration/jwks',
            authorization_endpoint: settings.authority + '/connect/authorize',
            token_endpoint: settings.authority + '/connect/token',
            userinfo_endpoint: settings.authority + '/connect/userinfo',
            end_session_endpoint: settings.authority + '/connect/endsession',
            check_session_iframe: settings.authority + '/connect/checksession',
            revocation_endpoint: settings.authority + '/connect/revocation',
            introspection_endpoint: settings.authority + '/connect/introspect',
        };
    }
}
