import { OemId } from 'helpers/OemId';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { match } from 'ts-pattern';
import FordIngestionManager from './Ford/FordIngestionManager';
import GMIngestionManager from './GMC/GMIngestionManager';
import HyundaiIngestionManager from './Hyundai/HyundaiIngestionManager';
import GenesisIngestionManager from './Hyundai/GenesisIngestionManager';
import MitsubishiIngestionManager from './Mitsubishi/MitsubishiIngestionManager';
import KiaIngestionManager from './Kia/KiaIngestionManager';

const IngestionManager = () => {
    const { oemId } = useParams();
    const parsedOemId = parseInt(oemId) as OemId;

    const manager = useMemo(() => {
        return match(parsedOemId)
            .with(OemId.Ford, () => <FordIngestionManager />)
            .with(OemId.GMC, () => <GMIngestionManager />)
            .with(OemId.Hyundai, () => <HyundaiIngestionManager />)
            .with(OemId.Genesis, () => <GenesisIngestionManager />)
            .with(OemId.Mitsubishi, () => <MitsubishiIngestionManager />)
            .with(OemId.Kia, () => <KiaIngestionManager />)
            .run();
    }, [parsedOemId]);

    return manager;
};

export default IngestionManager;
