export const messages = Object.freeze({
    organizationDisabledActionNotAllowed: 'Action not allowed as Organization deactivated',
    companyDisabledActionNotAllowed: 'Action not allowed as Location is deactivated',
    deactivationDisabledForTestOrgLocations: 'Action not allowed for locations in test organizations',
    deactivationDisabledForTestOrgs: 'Action not allowed for test organizations',
});

export const testOrganizations = Object.freeze([1, 2, 3, 4]);
export const automatedTestsOrganizations = Object.freeze([2, 3]);

export const customStyles = Object.freeze({
    tooltip: { backgroundColor: '#17A2B8' },
});

export const globalSearchType = Object.freeze({ all: 0, organization: 1, company: 2, user: 3 });
