import { useEffect, useContext, useState, useMemo, useCallback, useRef } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
    requestOrganization,
    requestOrganizationUsers,
    requestDisableUser,
    requestEnableUser,
    requestSendForgotPasswordEmail,
    deletePendingUser,
    requestSendBulkActivationEmail,
} from 'api/SecurityApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faStopCircle,
    faPlayCircle,
    faEnvelope,
    faTrash,
    faArrowsTurnRight,
    faUnlockKeyhole,
} from '@fortawesome/free-solid-svg-icons';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import withButton from '../../../Shared/Utils/withButton';
import ApiService from 'api/ApiService';
import { ApiEndpoints } from 'api/config/ApiEndpoints';
import { useSearchParams } from 'react-router-dom';
import { globalSearchType, messages, automatedTestsOrganizations } from '../../../Shared/Utils/Constants';
import FloatingMenu from 'components/Shared/FloatingMenu/FloatingMenu';

const tableColumnDetailsConfig = [
    { title: 'Id', columnProperty: 'userId', sortable: true, show: true },
    { title: 'First Name', columnProperty: 'firstName', sortable: true, show: true },
    { title: 'Last Name', columnProperty: 'lastName', sortable: true, show: true },
    { title: 'Username', columnProperty: 'userName', sortable: true, show: true },
    { title: 'Phone', columnProperty: 'phoneNumber', sortable: true, show: true },
    { title: 'Locations', columnProperty: 'locations', textProperty: 'companyName', sortable: true, show: true },
    {
        title: 'Organization',
        columnProperty: 'organizationName',
        sortable: true,
        show: false,
    },
    { title: 'Roles', columnProperty: 'role', sortable: true, show: true },
    {
        title: 'Status',
        columnProperty: 'status',
        textProperty: 'statusText',
        filterable: true,
        sortable: true,
        show: true,
    },
];

const useOrganizationUsers = () => {
    // higher order states
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { organizationId } = params;
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { notifications } = useContext(NotificationsContext);
    const { hasRole } = useContext(AccessControlContext);
    const [selectedUsers, setSelectedUsers] = useState([]);
    // local states
    const userAccess = useMemo(() => {
        return {
            hasCustomerSupportRole: hasRole(roles.customerSupport),
            hasAdminRole: hasRole(roles.admin),
            hasCustomerSuccesstRole: hasRole(roles.customerSuccess),
            hasSiteAdminRole: hasRole(roles.siteAdmin),
        };
    }, [hasRole]);
    const [org, setOrg] = useState(null);
    const [orgUsers, setOrgUsers] = useState([]);
    const [displayUsers, setDisplayUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpenUserDisable, setShowUserDisableModal] = useState(false);
    const [isOpenDeleteUser, setIsOpenDeleteUser] = useState(false);
    const [isOpenSendForgotPasswordEmail, setShowUserForgotPasswordEmailModal] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);
    const [tableColumnDetails, setTableColumnDetails] = useState(tableColumnDetailsConfig);
    const globalSearchRef = useRef(null);

    const [searchParams] = useSearchParams();

    const searchParamText = searchParams.get('search');
    const searchParamKey = searchParams.get('searchKey');

    useEffect(() => {
        setTableColumnDetails(configs => {
            configs.forEach(config => {
                if (config.filterable) {
                    if (!config.filterConfig) {
                        config.filterConfig = {};
                    }
                    config.filterConfig.filterOptions = Array.from(
                        new Set(orgUsers.map(user => user[config.textProperty] ?? user[config.columnProperty]))
                    ).map(x => ({
                        label: x,
                        value: x,
                    }));
                }
                if (config.columnProperty === 'organizationName') config.show = searchParamText != null;
            });
            return configs;
        });
    }, [orgUsers, searchParamText]);

    const [selectedFilters, setSelectedFilters] = useState([]);

    const isOrgActive = org?.isActive;
    const isTestOrganization = automatedTestsOrganizations.includes(parseInt(organizationId));
    const [showMoveUserModal, setShowMoveUserModal] = useState(false);
    const [editedMoveUser, setEditedMoveUser] = useState(null);
    const hasMoveUserAccess = useMemo(() => {
        return {
            hasAdminRole: hasRole(roles.admin),
            hasCustomerSuccesstRole: hasRole(roles.customerSuccess),
            hasSiteAdminRole: hasRole(roles.siteAdmin),
        };
    }, [hasRole]);

    //Updating navbar breadcrumb
    useEffect(() => {
        if (!org || (location && location.state && location.state.org)) return;
        navigate(`${location.pathname}${location.search}`, { state: { org } });
    }, [navigate, location, org]);

    useEffect(() => {
        const getOrg = async () => {
            if (location && location.state && location.state.org) {
                setOrg(location.state.org);
            } else if (organizationId) {
                try {
                    incrementLoading();
                    const orgResp = await requestOrganization(organizationId);
                    setOrg(orgResp);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };

        getOrg();
    }, [organizationId, showToast, incrementLoading, decrementLoading, location]);

    const fetchOrgUsers = useCallback(async (organizationId, userId, searchParamText) => {
        let users = [];
        if (searchParamText) {
            setSelectedFilters([]);
            const searchRes = await ApiService.post(ApiEndpoints.organization.globalSearch, {
                searchText: searchParamText,
                searchType: globalSearchType.user,
            });
            users = searchRes?.user?.results ?? [];
        } else if (userId) {
            setSelectedFilters([]);
            const user = await ApiService.get(ApiEndpoints.user.fetchById(userId));
            users = [user];
        } else {
            users = await requestOrganizationUsers(organizationId);
        }

        setOrgUsers(users);
        setDisplayUsers(users);
        if (globalSearchRef.current) {
            globalSearchRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const getOrgUsers = async () => {
            try {
                incrementLoading();
                await fetchOrgUsers(organizationId, searchParamKey, searchParamText);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        getOrgUsers();
    }, [organizationId, showToast, incrementLoading, decrementLoading, searchParamKey, searchParamText, fetchOrgUsers]);

    const handleDeletePendingUser = useCallback(async () => {
        try {
            incrementLoading();
            const response = await deletePendingUser(editUser.userId);
            if (response.status === 204) {
                notifications.pushSuccess(
                    `User ${editUser.firstName} ${editUser.lastName} has been successfully deleted.`
                );
                await fetchOrgUsers(organizationId, searchParamKey, searchParamText);
                const updatedSelectedUsers = selectedUsers.filter(user => {
                    return orgUsers.find(orgUser => orgUser.userId === user.id);
                });
                setSelectedUsers(updatedSelectedUsers);
                setEditUser(null);
                setIsOpenDeleteUser(false);
            }
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    }, [
        incrementLoading,
        editUser,
        notifications,
        organizationId,
        selectedUsers,
        showToast,
        decrementLoading,
        searchParamKey,
        searchParamText,
        fetchOrgUsers,
        orgUsers,
    ]);

    const handleShowDeletePendingUserModal = useCallback((e, user) => {
        setEditUser(user);
        setIsOpenDeleteUser(true);
        e.stopPropagation();
    }, []);

    const handleShowMoveUserModal = useCallback(user => {
        setShowMoveUserModal(prevShowMoveUserModal => !prevShowMoveUserModal);
        setEditedMoveUser(user);
    }, []);

    // Search Users
    useEffect(() => {
        if (orgUsers.length > 0) {
            let newSortedAndSearchedOrgUsers = orgUsers.slice();

            //Add data at base level of object to access in generic table component
            newSortedAndSearchedOrgUsers.forEach(u => {
                const isUserInActiveCompany = u.companies?.find(c => !c.isDeactivated);
                //If user is customer support hide all controls except forgot password
                u.actions = [];
                const enableActions = (isOrgActive || u.organization?.isActive) && isUserInActiveCompany;
                const actionsDisabledMessage = isTestOrganization
                    ? messages.deactivationDisabledForTestOrgs
                    : !(isOrgActive || u.organization?.isActive)
                    ? messages.organizationDisabledActionNotAllowed
                    : messages.companyDisabledActionNotAllowed;

                if (userAccess.hasCustomerSupportRole) {
                    const EmailButtonAlt = withButton(() => (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-resend`}
                            className="text-primary clickable m-1 customer-action-button"
                            icon={faEnvelope}
                            onClick={e => handleForgotEmailClick(e, u)}
                        />
                    ));
                    u.actions = [<EmailButtonAlt key={`email-${u.userId}`} isActive={u.isActive} />];
                } else {
                    const EditButton = withButton(() => (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-edit`}
                            className="text-primary clickable customer-action-button"
                            icon={faEdit}
                        />
                    ));
                    const ActOrDeactButton = withButton(() =>
                        u.isDeactivated ? (
                            <FontAwesomeIcon
                                id={`user-${u.userId}-activate`}
                                className="text-primary clickable customer-action-button"
                                icon={faPlayCircle}
                            />
                        ) : (
                            <FontAwesomeIcon
                                id={`user-${u.userId}-deactivate`}
                                className="text-primary clickable customer-action-button"
                                icon={faStopCircle}
                            />
                        )
                    );
                    const EmailButton = withButton(() => (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-resend`}
                            className="text-primary clickable customer-action-button"
                            icon={faUnlockKeyhole}
                        />
                    ));
                    const DeleteButton = withButton(() => (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-delete`}
                            className="text-primary clickable customer-action-button"
                            icon={faTrash}
                        />
                    ));
                    const MoveUserButton = withButton(() => (
                        <FontAwesomeIcon
                            id={`user-${u.userId}-move`}
                            className="text-primary clickable customer-action-button"
                            icon={faArrowsTurnRight}
                        />
                    ));

                    u.actions = [
                        <EditButton
                            key={`edit-${u.userId}`}
                            isActive={enableActions}
                            label="Edit Information"
                            onClick={e => handleEditClick(e, u)}
                            title={actionsDisabledMessage}
                        />,
                        <ActOrDeactButton
                            key={`activate-${u.userId}`}
                            isActive={enableActions}
                            label={u.isDeactivated ? 'Activate' : 'Deactivate'}
                            onClick={u.isDeactivated ? e => handleEnableClick(e, u) : e => handleDisableClick(e, u)}
                            title={actionsDisabledMessage}
                        />,
                        <EmailButton
                            key={`email-${u.userId}`}
                            isActive={!isTestOrganization && enableActions}
                            label={'Send Password Reset'}
                            onClick={e => handleForgotEmailClick(e, u)}
                            title={actionsDisabledMessage}
                        />,
                    ];

                    if (userAccess.hasAdminRole || userAccess.hasCustomerSuccesstRole || userAccess.hasSiteAdminRole) {
                        u.actions.push(
                            <DeleteButton
                                isActive={enableActions && !u.emailConfirmed && !u.isDeactivated}
                                title={
                                    u.emailConfirmed || u.isDeactivated
                                        ? 'Only User with Pending status can be deleted'
                                        : actionsDisabledMessage
                                }
                                label={'Delete'}
                                onClick={e => handleShowDeletePendingUserModal(e, u)}
                            />
                        );

                        u.actions.push(
                            <MoveUserButton
                                isActive={enableActions}
                                label={'Move User'}
                                onClick={() => handleShowMoveUserModal(u)}
                                title={actionsDisabledMessage}
                            />
                        );
                    }
                }

                const secondaryLocations = u.companies?.filter(p => p.companyId != u.company?.companyId);
                u.companyName = u.company ? u.company.companyName : '';
                u.locations = secondaryLocations?.length ? (
                    <div className="locations-cell">
                        <span role="heading" data-testid="primary-location">
                            {u.company?.companyName}
                        </span>
                        <FloatingMenu
                            buttonId="secondary-locations-button"
                            labelId="locations-counter"
                            label={`(+${secondaryLocations.length})`}
                            items={secondaryLocations.map(comp => (
                                <li key={comp.companyId}>{comp.companyName}</li>
                            ))}
                        />
                    </div>
                ) : (
                    <span>{u.company?.companyName}</span>
                );

                u.role = u.roles && u.roles.join(', ');
                u.status =
                    !u.isDeactivated && u.emailConfirmed ? (
                        <span className="text-success">Active</span>
                    ) : u.isDeactivated ? (
                        <span className="text-danger">Deactivated</span>
                    ) : (
                        <span className="text-warning">Pending</span>
                    );
                u.statusText =
                    !u.isDeactivated && u.emailConfirmed ? 'Active' : u.isDeactivated ? 'Deactivated' : 'Pending';
                u.organizationName = u.organization?.name;
            });

            //If there is a column requested to be sorted by, sort by that property and the sort direction
            if (sortedPropertyKey) {
                newSortedAndSearchedOrgUsers.sort((a, b) => {
                    const aValue =
                        sortedPropertyKey === 'status'
                            ? a['statusText']
                            : sortedPropertyKey === 'locations'
                            ? a['companyName']
                            : sortedPropertyKey === 'userId'
                            ? a[sortedPropertyKey]
                            : sortedPropertyKey === 'phoneNumber'
                            ? a[sortedPropertyKey]
                                ? a[sortedPropertyKey].replace(/[^\d]/g, '')
                                : 0
                            : a[sortedPropertyKey]?.toLowerCase();

                    const bValue =
                        sortedPropertyKey === 'status'
                            ? b['statusText']
                            : sortedPropertyKey === 'locations'
                            ? b['companyName']
                            : sortedPropertyKey === 'userId'
                            ? b[sortedPropertyKey]
                            : sortedPropertyKey === 'phoneNumber'
                            ? b[sortedPropertyKey]
                                ? b[sortedPropertyKey].replace(/[^\d]/g, '')
                                : 0
                            : b[sortedPropertyKey]?.toLowerCase();

                    if (sortedPropertyDirection === 'A') {
                        return aValue < bValue ? -1 : 1;
                    } else {
                        return aValue < bValue ? 1 : -1;
                    }
                });
            }

            if (searchTerm) {
                newSortedAndSearchedOrgUsers = newSortedAndSearchedOrgUsers.filter(
                    u =>
                        u.userId.toString().indexOf(searchTerm) > -1 ||
                        (u.firstName && u.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (u.lastName && u.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (u.email && u.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                        (u.phoneNumber && u.phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
                );
            }

            if (selectedFilters.length) {
                for (let filter of selectedFilters) {
                    newSortedAndSearchedOrgUsers =
                        filter.optionsSelected &&
                        newSortedAndSearchedOrgUsers.filter(u =>
                            filter.optionsSelected.some(x => x.toLowerCase() == u[filter.columnName].toLowerCase())
                        );
                }
            }

            setDisplayUsers(newSortedAndSearchedOrgUsers);
        } else {
            setDisplayUsers([]);
        }
    }, [
        sortedPropertyKey,
        sortedPropertyDirection,
        orgUsers,
        searchTerm,
        userAccess,
        handleShowDeletePendingUserModal,
        isOrgActive,
        handleShowMoveUserModal,
        selectedFilters,
        isTestOrganization,
    ]);

    const onSaveOrEdit = async () => {
        try {
            incrementLoading();
            await fetchOrgUsers(organizationId, searchParamKey, searchParamText);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleToggle = () => {
        if (showModal) setEditUser(null);
        setShowModal(!showModal);
    };

    const handleEditClick = (e, user) => {
        setEditUser(user);
        setShowModal(true);
        e.stopPropagation();
    };

    const handleDisableEnable = async () => {
        try {
            incrementLoading();
            if (editUser.isDeactivated) await requestEnableUser(editUser.userId);
            else await requestDisableUser(editUser.userId);
            await fetchOrgUsers(organizationId, searchParamKey, searchParamText);
            const updatedSelectedUsers = selectedUsers.filter(user => {
                let updatedUser = orgUsers.find(orgUser => orgUser.userId === user.id);
                return !updatedUser.isDeactivated;
            });
            setSelectedUsers(updatedSelectedUsers);
            setShowUserDisableModal(false);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleDisableClick = async (e, user) => {
        setEditUser(user);
        setShowUserDisableModal(true);
        e.stopPropagation();
    };

    const handleEnableClick = async (e, user) => {
        setEditUser(user);
        setShowUserDisableModal(true);
        e.stopPropagation();
    };

    const handleForgotEmailClick = async (e, user) => {
        setEditUser(user);
        setShowUserForgotPasswordEmailModal(true);
        e.stopPropagation();
    };

    useEffect(() => {
        if (!isOpenUserDisable && !isOpenSendForgotPasswordEmail && !showModal) {
            setEditUser(null);
        }
    }, [isOpenUserDisable, isOpenSendForgotPasswordEmail, showModal]);

    const handleSendForgotPassword = async () => {
        try {
            incrementLoading();
            await requestSendForgotPasswordEmail(editUser.email);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
            setShowUserForgotPasswordEmailModal(false);
            setEditUser(null);
        }
    };

    const handleBulkSendActivationEmail = useCallback(async () => {
        try {
            const emails = selectedUsers.map(s => s.item.email);
            await requestSendBulkActivationEmail(emails);
            setSelectedUsers([]);
            notifications.pushSuccess('Activation Email(s) successfully sent to the selected users');
        } catch (error) {
            notifications.pushExceptionDanger(new Error(error));
        }
    }, [notifications, selectedUsers]);

    const sortClickCallback = (retSortedProperty, retSortedPropertyDirection) => {
        setSortedPropertyKey(retSortedProperty);
        setSortedPropertyDirection(retSortedPropertyDirection);
    };

    const filterChangeCallback = filters => {
        setSelectedFilters(prevFilters => {
            let updatedFilters = prevFilters.filter(filter => filter.columnName != filters.columnName);
            if (filters.optionsSelected.length) {
                updatedFilters = [...updatedFilters, filters];
            }
            return updatedFilters;
        });
    };

    return {
        editUser,
        organizationId,
        showModal,
        tableColumnDetails,
        isOpenUserDisable,
        isOpenSendForgotPasswordEmail,
        org,
        searchTerm,
        displayUsers,
        userAccess,
        onSaveOrEdit,
        handleToggle,
        setShowUserDisableModal,
        handleDisableEnable,
        setShowUserForgotPasswordEmailModal,
        handleSendForgotPassword,
        setSearchTerm,
        setShowModal,
        sortClickCallback,
        isOpenDeleteUser,
        setIsOpenDeleteUser,
        handleDeletePendingUser,
        handleBulkSendActivationEmail,
        selectedUsers,
        setSelectedUsers,
        isOrgActive,
        showMoveUserModal,
        handleShowMoveUserModal,
        editedMoveUser,
        hasMoveUserAccess,
        filterChangeCallback,
        selectedFilters,
        searchParamText,
        globalSearchRef,
        isTestOrganization,
    };
};

export default useOrganizationUsers;
