import { requestImportJobBookSummary, requestImportJobHistoryByOemId, requestRefreshOem } from 'api/RepairProcedureApi';
import useOems from 'hooks/useOems';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ActionsCell, BookSummaryCell, DownloadTypeCell, OemCell, ScheduleCell, StatusCell } from './CustomCells';
import type { ItemReturnEvent } from 'components/Shared/Table/types';
import {
    ImportJobBookSummary,
    ImportJobSummary,
    RefreshDashboardRowDataType,
    RefreshDashboardActionTypes,
} from './types';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    requestGetOemRefreshSchedules,
    requestSkipNextRefreshById,
    requestUpdateRefreshScheduleById,
} from 'api/vehicleInfo';
import { OemRefreshSchedule } from '../types';
import useImportJobDownloadType from 'hooks/useImportJobDownloadType';
import { OemId } from 'helpers/OemId';

const headers = [
    {
        label: 'OEM',
        component: OemCell,
    },
    {
        label: 'Status',
        component: StatusCell,
    },
    {
        label: 'Books',
        component: BookSummaryCell,
    },
    {
        label: 'Download Type',
        thClass: 'text-center',
        component: DownloadTypeCell,
    },
    {
        label: 'Next Scheduled Run',
        component: ScheduleCell,
    },
    {
        label: 'Actions',
        thClass: 'text-center',
        component: ActionsCell,
    },
];

// Note: oem ids are hard coded for the time being, change as needed
const myOemIds = [
    OemId.Ford,
    OemId.Toyota,
    OemId.Nissan,
    OemId.GMC,
    OemId.Honda,
    OemId.Subaru,
    OemId.Volkswagen,
    OemId.Chrysler,
    OemId.Hyundai,
    OemId.Genesis,
    OemId.Mitsubishi,
    OemId.Kia,
    OemId.OEMiQ,
] as const;

const getLastImportJobSummaryForOem = async (oemId: number): Promise<ImportJobSummary> => {
    const lastImportJobs = await requestImportJobHistoryByOemId(oemId, 1);
    if (lastImportJobs.length === 0) {
        return null;
    }

    const lastImportJob = lastImportJobs[0];

    const result = await requestImportJobBookSummary(lastImportJob.importJobId);
    const bookSummary: ImportJobBookSummary = {
        new: result.newRpBookCount,
        updated: result.updatedRpBookCount,
        success: result.successCount,
        error: result.errorCount,
        inProgress: result.inProgressCount,
    };

    const lastImportJobSummary: ImportJobSummary = {
        importJobId: lastImportJob.importJobId,
        success: lastImportJob.success === undefined ? null : lastImportJob.success,
        procedureSummary: lastImportJob.procedureSummary ?? null,
        numberOfBooks: lastImportJob.numberOfBooks ?? null,
        bookSummary: bookSummary,
        createdAt: lastImportJob.createdAt,
        updatedAt: lastImportJob.updatedAt ?? null,
    };

    return lastImportJobSummary;
};

const useImportManagerDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { oems } = useOems();
    const { importJobDownloadTypes } = useImportJobDownloadType();
    const { notifications } = useContext(NotificationsContext);
    const confirmationRef = useRef(null);
    const [data, setData] = useState<RefreshDashboardRowDataType[]>([]);
    const [selectedScheduleId, setSelectedScheduleId] = useState<number | null>(null);

    useEffect(() => {
        let isUnmounted = false;
        (async () => {
            const myOems = oems
                .filter(oem => myOemIds.find(id => oem.oemId === id))
                .sort((a, b) => (a.oemId < b.oemId ? -1 : 1));
            const myData = myOems.map<RefreshDashboardRowDataType>(oem => ({
                oemId: oem.oemId,
                oemName: oem.oemName,
                logo: oem.logo,
                isLoading: true,
                lastImportJobSummary: null,
                oemRefreshSchedules: null,
            }));

            if (!isUnmounted) {
                setData(myData);
            }

            let oemRefreshSchedules: OemRefreshSchedule[] = [];
            try {
                oemRefreshSchedules = await requestGetOemRefreshSchedules();
                setData(prev =>
                    prev.map(data => {
                        const oemRefreshSchedule = oemRefreshSchedules.filter(rs => rs.oemId === data.oemId) ?? null;
                        if (!oemRefreshSchedule) {
                            throw new Error(`Failed to get next scheduled run for oem ${data.oemId}`);
                        }
                        return { ...data, oemRefreshSchedules: oemRefreshSchedule };
                    })
                );
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }

            //Move just OEMiQ record to front of list so it gets stats first for faster test execution.
            //Sorting here still allows the OEMiQ record to be at bottom of list even though it's loading first.
            const specificIndex = myOems.findIndex(item => item.oemId === 100);
            if (specificIndex > 0) {
                const [itemToMove] = myOems.splice(specificIndex, 1);
                myOems.unshift(itemToMove);
            }

            for (const oem of myOems) {
                try {
                    if (!isUnmounted) {
                        const lastImportJobSummary = await getLastImportJobSummaryForOem(oem.oemId);
                        if (!isUnmounted) {
                            setData(prev =>
                                prev.map(data =>
                                    data.oemId === oem.oemId
                                        ? {
                                              ...data,
                                              isLoading: false,
                                              lastImportJobSummary: lastImportJobSummary,
                                          }
                                        : data
                                )
                            );
                        }
                    }
                } catch (error) {
                    notifications.pushExceptionDanger(error);
                    if (!isUnmounted) {
                        setData(prev =>
                            prev.map(data => (data.oemId === oem.oemId ? { ...data, isLoading: false } : data))
                        );
                    }
                }
            }
        })();

        return () => {
            isUnmounted = true;
        };
    }, [oems, notifications]);

    const _reloadDataForOem = useCallback(
        async (oemId: number): Promise<void> => {
            try {
                setData(prev =>
                    prev.map(data =>
                        data.oemId === oemId
                            ? {
                                  ...data,
                                  isLoading: true,
                              }
                            : data
                    )
                );
                const oemRefreshSchedules = await requestGetOemRefreshSchedules();
                const lastImportJobSummary = await getLastImportJobSummaryForOem(oemId);
                setData(prev =>
                    prev.map(data =>
                        data.oemId === oemId
                            ? {
                                  ...data,
                                  isLoading: false,
                                  lastImportJobSummary: lastImportJobSummary,
                                  oemRefreshSchedules: oemRefreshSchedules.filter(rs => rs.oemId === oemId),
                              }
                            : data
                    )
                );
            } catch (error) {
                setData(prev =>
                    prev.map(data =>
                        data.oemId === oemId
                            ? { ...data, isLoading: false, lastImportJobSummary: null, oemRefreshSchedules: null }
                            : data
                    )
                );
                notifications.pushExceptionDanger(error);
            }
        },
        [notifications]
    );

    const handleEditScheduleButtonClick = useCallback((payload: ItemReturnEvent) => {
        setSelectedScheduleId(parseInt(payload.id));
    }, []);

    const handleDetailsButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            const item = payload.item as RefreshDashboardRowDataType;
            const destination = `${location.pathname}/${item.oemId}/${item.lastImportJobSummary.importJobId}`;
            navigate(destination);
        },
        [navigate, location]
    );

    const handleHistoryButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            const item = payload.item as RefreshDashboardRowDataType;
            const destination = `${location.pathname}/${item.oemId}`;
            navigate(destination);
        },
        [navigate, location]
    );

    const handleRunRefreshButtonClick = useCallback(
        (payload: ItemReturnEvent) => {
            if (confirmationRef.current) {
                confirmationRef.current.open(
                    async () => {
                        try {
                            const oemId = payload.item.oemId as number;
                            const schedule = (payload.item.oemRefreshSchedules as OemRefreshSchedule[]).find(
                                rs => rs.id === parseInt(payload.id)
                            );

                            await requestRefreshOem(oemId, schedule.downloadTypeId);
                            await _reloadDataForOem(oemId);
                            notifications.pushSuccess(`A refresh run for ${payload.item.oemName} has been started`);
                        } catch (error) {
                            notifications.pushExceptionDanger(error);
                        }
                    },
                    {
                        title: 'Confirmation',
                        body: `Are you sure to run a refresh for ${payload.item.oemName}? Make sure that teamleads are informed about this action!`,
                    }
                );
            }
        },
        [_reloadDataForOem, notifications]
    );

    const handleReloadButtonClick = useCallback(
        async ({ id }: ItemReturnEvent) => {
            const oemId = parseInt(id);
            await _reloadDataForOem(oemId);
        },
        [_reloadDataForOem]
    );

    const tableHandlers = useMemo(
        () =>
            ({
                [RefreshDashboardActionTypes.EditNextScheduledRun]: handleEditScheduleButtonClick,
                [RefreshDashboardActionTypes.ReloadOemStatistics]: handleReloadButtonClick,
                [RefreshDashboardActionTypes.NavigateToDetailsPage]: handleDetailsButtonClick,
                [RefreshDashboardActionTypes.NavigateToHistoryPage]: handleHistoryButtonClick,
                [RefreshDashboardActionTypes.RunRefresh]: handleRunRefreshButtonClick,
            } as const),
        [
            handleEditScheduleButtonClick,
            handleReloadButtonClick,
            handleDetailsButtonClick,
            handleHistoryButtonClick,
            handleRunRefreshButtonClick,
        ]
    );

    const handleEditScheduleModalToggle = useCallback(() => setSelectedScheduleId(null), []);
    const handlePauseButtonClick = useCallback(
        async (id: number) => {
            try {
                setSelectedScheduleId(null); // close modal
                setData(prev =>
                    prev.map(d => (d.oemRefreshSchedules.find(s => s.id === id) ? { ...d, isLoading: true } : d))
                );
                const result = await requestSkipNextRefreshById(id);
                setData(prev =>
                    prev.map(d =>
                        d.oemRefreshSchedules.find(s => s.id === id)
                            ? {
                                  ...d,
                                  oemRefreshSchedules: d.oemRefreshSchedules.map(r =>
                                      r.id === result.id ? result : r
                                  ),
                                  isLoading: false,
                              }
                            : d
                    )
                );
                notifications.pushSuccess(`Skip next refresh for ${result.oem.oemName} successfully!`);
            } catch (error) {
                notifications.pushExceptionDanger(error);
                setData(prev =>
                    prev.map(d => (d.oemRefreshSchedules.find(s => s.id === id) ? { ...d, isLoading: false } : d))
                );
            }
        },
        [notifications]
    );
    const handleSaveButtonClick = useCallback(
        async (id: number, isActive: boolean) => {
            try {
                setSelectedScheduleId(null); // close modal
                setData(prev =>
                    prev.map(d => (d.oemRefreshSchedules.find(s => s.id === id) ? { ...d, isLoading: true } : d))
                );
                const result = await requestUpdateRefreshScheduleById(id, isActive);
                setData(prev =>
                    prev.map(d =>
                        d.oemRefreshSchedules.find(s => s.id === id)
                            ? {
                                  ...d,
                                  oemRefreshSchedules: d.oemRefreshSchedules.map(r =>
                                      r.id === result.id ? result : r
                                  ),
                                  isLoading: false,
                              }
                            : d
                    )
                );
                notifications.pushSuccess(`Update refresh schedule for ${result.oem.oemName} successfully!`);
            } catch (error) {
                notifications.pushExceptionDanger(error);
                setData(prev =>
                    prev.map(d => (d.oemRefreshSchedules.find(s => s.id === id) ? { ...d, isLoading: false } : d))
                );
            }
        },
        [notifications]
    );
    const modalHandlers = useMemo(
        () =>
            ({
                onToggle: handleEditScheduleModalToggle,
                onPause: handlePauseButtonClick,
                onSave: handleSaveButtonClick,
            } as const),
        [handleEditScheduleModalToggle, handlePauseButtonClick, handleSaveButtonClick]
    );

    const selectedSchedule = selectedScheduleId
        ? data.flatMap(d => d.oemRefreshSchedules ?? []).find(s => s.id === selectedScheduleId)
        : null;

    return {
        headers,
        data,
        tableHandlers,
        confirmationRef,
        selectedSchedule,
        modalHandlers,
        importJobDownloadTypes,
    };
};

export default useImportManagerDashboard;
